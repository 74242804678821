.title {
  margin-bottom: 0;
  font-size: 36px;
  line-height: 100%;
  font-weight: 900;
  color: #441249;

  @media (max-width: 520px) {
    font-size: 30px;
  }
}