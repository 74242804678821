.contacts__title {
  font-size: 36px;
  font-weight: 900;
  color: #441249;
}

.contacts__subtitle {
  font-size: 24px;
  font-weight: 700;
  color: #441249;
}

.contacts__info {
  font-size: 16px;
}

.contacts__row {
  margin-bottom: 20px;
}

.contacts__socials {
  gap: 22px;

  @media (max-width: 520px) {
    gap: 14px;
  }
}

.contacts__socials .socials__link {
  max-width: 38px;
}

.contacts__socials img {
  width: 38px;
  height: 38px;
}

.contacts__socials .socials__link_tg {
  max-width: 40px;

  & img {
    width: 40px;
  }
}