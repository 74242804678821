.questions {
  position: relative;
  padding-bottom: 130px;

  @media (max-width: 992px) {
    padding-bottom: 80px;
  }
}

.questions__img-block {
  max-width: 258px;
}

.question__center {
  display: flex;
  justify-content: center;

  @media (max-width: 992px) {
    order: 0;
  }
}

.question__first {
  @media (max-width: 992px) {
    order: 1;
  }
}

.question__second {
  @media (max-width: 992px) {
    order: 2;
  }
}

.question__contacts {
  padding-left: 40px;

  @media (max-width: 1200px) {
    padding-left: 0;
  }

  @media (max-width: 992px) {
    padding-top: 60px;
  }

  @media (max-width: 359px) {
    margin-left: -25px;
  }
}

