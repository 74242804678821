.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -10000px;
  right: 10000px;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: rgba(rgb(21, 21, 21), .7);
  opacity: 0;
  transition: opacity 0.5s;
  backdrop-filter: blur(10px);
}

.modal_active {
  left: 0;
  right: 0;
  opacity: 1;
}

.modal__inner {
  position: relative;
  flex-basis: 520px;
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  background-color: #fff;
}

.modal__form {
  padding: 60px;
  border-radius: 15px;

  @media (max-width: 768px) {
    padding: 45px;
  }

  @media (max-width: 520px) {
    padding: 45px 25px;
  }

  @media (max-width: 359px) {
    padding: 42px 18px 32px 18px;
  }

  .form__textarea {
    @media (max-width: 520px) {
      height: 100px;
    }
  }

  .form__subtitle {
    @media (max-width: 520px) {
      margin-bottom: 20px;
    }
  }

  .form__input {
    @media (max-width: 359px) {
      margin-bottom: 18px;
    }
  }
}

.modal__close {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
  width: 70px;
  height: 70px;
  border: none;
  background-color: transparent;
  opacity: .5;
  cursor: pointer;

  &:after, &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 2px;
    background-color: rgba(#000, .4);
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  @media (max-width: 520px) {
    width: 50px;
    height: 50px;
  }
}