@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-SemiBold.woff2") format("woff2"),
       url('/fonts/Montserrat-SemiBold.woff') format('woff');

  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Musea";
  src: url('/fonts/MuseoSansCyrl300.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Musea";
  src: url('/fonts/MuseoSansCyrl500.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Musea";
  src: url('/fonts/MuseoSansCyrl700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Musea";
  src: url('/fonts/MuseoSansCyrl900.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

