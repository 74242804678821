.wave {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100px;
  background-image: url("/img/Vector-1.png");
  background-size: 100% 100%;
  background-position: center top;
  background-repeat: no-repeat;
  box-shadow: 0 -10px 15px #f3f3f3;
  z-index: 2;

  @media (max-width: 1600px) {
    height: 80px;
  }

  @media (max-width: 1400px) {
    height: 60px;
  }

  @media (max-width: 992px) {
    height: 40px;
  }

  @media (max-width: 520px) {
    height: 25px;
  }
}

.wave_small {
  position: absolute;
  top: 0;
  width: 100%;
  height: 50px;
  background-image: url("/img/Vector-1.png");
  background-size: 100% 100%;
  background-position: center top;
  background-repeat: no-repeat;
  box-shadow: 0 -10px 15px #f3f3f3;
  z-index: 2;

  @media (max-width: 1600px) {
    height: 45px;
  }

  @media (max-width: 1400px) {
    height: 40px;
  }

  @media (max-width: 992px) {
    height: 30px;
  }

  @media (max-width: 520px) {
    height: 20px;
  }
}

