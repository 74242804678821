.header__container {
  display: flex;
  align-items: center;
  padding: 5px 0;
  color: #000;

  @media (max-width: 992px) {
    padding-left: 20px;
  }
}

.header__basic {
  display: flex;
  gap: 15px;

  @media (max-width: 1200px) {
    display: flex;
    width: 100%;
    justify-content: left;
    align-items: center;
  }
}

.header__logo {
  max-width: 220px;
}

.header__burger {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  z-index: 3;
  padding: 15px 15px 15px 15px;
  background-color: transparent;
  border: none;

  &.open {
    position: relative;
    right: 40px;
  }

  & span {
    position: absolute;
    height: 0.8px;
    width: 100%;
    border-radius: 15px;
    opacity: 0.8;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    background-color: #787878;
  }

  @media (max-width: 1200px) {
    margin-left: auto;
    position: relative;
    right: initial;
    width: auto;
    height: auto;
    margin: initial initial initial auto;
    clip-path: initial;
    clip: initial;
  }
}

.header__burger-inner {
  position: relative;
  width: 40px;
  height: 20px;
}

.header__burger span:nth-child(1) {
  top: 0px;
  transform-origin: left center;
}

.header__burger span:nth-child(2) {
  top: 10px;
  transform-origin: left center;
}

.header__burger span:nth-child(3) {
  top: 19px;
  transform-origin: left center;
}

.header__burger.open span:nth-child(1) {
  transform: rotate(45deg);
  top: -3px;
  left: 6px;
  width: 29px;
}

.header__burger.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.header__burger.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 17.33px;
  left: 6px;
  width: 29px;
}

.header__burger.open  {
  transform: rotate(-0deg);
  top: 0px;
  width: 29px;
}

.header__additional {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  gap: 20px;

  @media (max-width: 1200px) {
    position: absolute;
    left: -8000px;
  }
}

.header__nav {
  justify-self: center;
}

.header__right {
  display: flex;
  justify-self: right;
  align-items: center;
  gap: 28px;
}

.header__button {
  height: 36px;
  padding: 0 33px;
  border-radius: 20px;
  background-color: #fff;
  color: #33003B;
  text-align: center;
  line-height: 36px;
  font-weight: 600;
  transition: all .3s;

  &:hover {
    box-shadow: 0 0 20px #fff;
  }
}

.header_active {
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  background-color: #fff;

  .header__container {
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    padding-bottom: 20px;
  }

  .header__additional {
    flex-direction: column;

    @media (max-width: 1200px) {
      position: static;
      left: -8000px;
      flex-grow: 1;
      opacity: 0;

    //АНИМАЦИЯ ПОЯВЛЕНИЯ МЕНЮ В МОБИЛЬНОМ РАЗРЕШЕНИИ
      transform: translateY(-30px);
      animation: view .5s .1s forwards;
    }

    @keyframes view {
      0% {
        opacity: 0;
        transform: translateY(-30px);
      }

      100% {
        transform: translateY(0px);
        opacity: 1;
      }
    }

  }

  .header__right {
    flex-direction: column;
  }

  .header__nav {
    flex-direction: column;
  }
}

.header__tel {
  font-size: 16px;
  font-weight: 500;
}