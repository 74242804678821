.examples {
  padding-top: 80px;
  margin-bottom: 100px;

  @media (max-width: 768px) {
    padding-top: 40px;
  }
}

.examples__title {
  margin-bottom: 60px;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
}

.examples__list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.examples__wrap {
  position: relative;
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 1180px;
  border-radius: 50px;
  box-shadow: -2px 4px 20px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
}

.examples__tomatoes {
  position: absolute;
  bottom: -10px;
  right: -160px;
  width: 280px;
  height: 219px;

  @media (max-width: 1200px) {
    bottom: -50px;
    right: -180px;
    transform: scale(0.8);
  }

  @media (max-width: 992px) {
    right: -190px;
  }

  @media (max-width: 768px) {
    right: -60px;
    bottom: -130px;
  }
}

.example__tomatoes-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.examples__img-block {
  margin-left: -3px;
  border-radius: 50px;
  overflow: hidden;
  width: 40%;

  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
    height: 300px;
  }
}

.examples__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.examples__desc {
  padding: 85px 95px;

  @media (max-width: 1200px) {
    padding: 50px 50px;
  }

  @media (max-width: 992px) {
    padding: 40px 30px;
  }
}

.examples__subtitle {
  margin-bottom: 7px;
  font-size: 24px;
  font-weight: 700;
  line-height: 100%;
  color: #542259;

  @media (max-width: 992px) {
    font-size: 20px;
  }

  @media (max-width: 520px) {
    text-align: center;
  }
}

.examples__dl-wrap {
  display: flex;
  justify-content: space-between;
  gap: 70px;

  @media (max-width: 992px) {
    gap: 20px;
  }

  @media (max-width: 520px) {
    flex-direction: column;
    justify-content: center;
  }
}

.examples__name {
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;

  @media (max-width: 992px) {
    font-size: 17px;
  }

  @media (max-width: 520px) {
    text-align: center;
  }
}

.examples__value {
  flex-shrink: 0;
  text-align: right;
  font-size: 16px;
  font-weight: 500;

  @media (max-width: 520px) {
    text-align: center;
  }
}

.examples__mini-row {
  display: flex;
  gap: 25px;
  justify-content: right;

  @media (max-width: 520px) {
    justify-content: center;
  }
}