.how {
  position: relative;
  margin-top: 100px;
  margin-bottom: 60px;
  padding-top: 60px;
  padding-bottom: 120px;
  background-color: #f2f2f2;

  @media (max-width: 768px) {
    margin-top: 40px;
    margin-bottom: 40px;
    padding-bottom: 60px;
  }
}

.how__wave {
  top: initial;
  bottom: calc(100% - 3px);
  background-image: url("/img/small-wave-top.png");
  background-color: transparent;
  box-shadow: 0 5px 5px #f2f2f2;
}

.how__wave_bottom {
  top: calc(100% - 3px);
  bottom: initial;
  background-image: url("/img/small-wave-bottom.png");
  background-color: transparent;
  box-shadow: 0 -5px 5px #f2f2f2;
}

.how__title {
  margin-bottom: 60px;
  text-align: center;
}

.how__block {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  min-height: 320px;
  height: 100%;
  border-radius: 50px;
  padding: 33px 48px 41px;
  background-color: #fff;
  transition: all .3s;

  &::after {
    content: "";
    position: absolute;
    right: -14px;
    top: 50%;
    transform: translateY(-50%);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #55C053;
    box-shadow: 26px 0 0 #55C053;
  }

  &::before {
    content: "";
    position: absolute;
    right: -29px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #55C053;
  }

  &:hover {
    transform: scale(1.2);
    box-shadow: -2px 4px 20px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 1600px) {
    padding: 33px 30px 41px;
  }

  @media (max-width: 768px) {
    border-radius: 30px;
    padding: 27px 20px 27px;
  }
}

.how__col:nth-child(even) {
  @media (max-width: 1200px) {
    & .how__block {
      &::before, &::after {
        display: none;
      }
    }
  }
}

.how__col:nth-child(even), .how__col:nth-child(odd) {
  @media (max-width: 576px) {
    & .how__block {
      &::before, &::after {
        display: block;
        top: calc(100% + 10px);
        left: 50%;
        transform: translate(-50%, 0) rotate(90deg);
      }

      &::before {
        top: calc(100% + 20px);
      }
    }
  }
}


.how__col:last-child {
  & .how__block {
    &::before, &::after {
      display: none;
    }
  }
}

.how__icon {
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &_request {
    background-image: url("/img/webp/how-1.webp")
  }

  &_pay {
    background-image: url("/img/webp/how-2.webp")
  }

  &_add {
    width: 50px;
    height: 50px;
    background-image: url("/img/webp/how-3.webp")
  }

  &_start {
    background-image: url("/img/webp/how-4.webp")
  }
}

.how__subtitle {
  font-size: 24px;
  font-weight: 700;
  color: #542259;
}

.how__desc {
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
}

.how__btn {
  margin-top: 35px;
  height: 44px;
  font-size: 14px;
  padding: 15px;
}