.hero {
  position: relative;
  padding-bottom: 70px;
  background-image: linear-gradient(to bottom, #F8F8F8, #f3f3f3);

  @media (max-width: 520px) {
    padding-bottom: 40px;
  }
}

.hero__title {
  margin-top: 75px;
  font-size: 48px;
  font-weight: 900;
  line-height: 110%;
  color: #7B0053;

  @media (max-width: 520px) {
    margin-top: 60px;
    font-size: 38px;
  }

  @media (max-width: 379px) {
    margin-top: 60px;
    font-size: 30px;
  }
}

.hero__desc {
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  color: #787878;

  @media (max-width: 992px) {
    max-width: 400px;
  }

  @media (max-width: 520px) {
    margin-bottom: 30px;
    font-size: 20px;
  }
}

.hero__left {
  position: relative;
  z-index: 1;
  padding-left: 112px;

  @media (max-width: 1200px) {
    padding-left: 0;
  }
}

.hero__right {
  position: relative;
}

.hero__bg {
  position: relative;
  width: 472px;
  height: 459px;
  margin-top: 30px;
  background-image: url("/img/webp/hero.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -50%);
    width: 115%;
    height: 115%;
    border: 3px solid transparent;
    border-radius: 50%;
    border-right: 2px solid #E4E4E4;
  }

  @media (max-width: 1250px) {
    margin-left: -50px;
    transform: scale(0.7);
  }

  @media (max-width: 992px) {
    transform: scale(0.81);
  }

  @media (max-width: 520px) {
    margin-left: -131px;
    margin-top: -70px;
    transform: scale(0.46);
  }
}

.hero__bg-item {
  position: absolute;
  left: 100%;
  font-size: 24px;
  font-weight: 700;
  color: #55C053;

  @media (max-width: 520px) {
    font-size: 35px;
  }
}

.hero__bg-item_first-line {
  top: 0;
  transform: translate(-35px, 20px);

  @media (max-width: 520px) {
    transform: translate(-35px, 10px);
  }
}

.hero__bg-item_second-line {
  top: 50%;
  transform: translate(35px, -50%);
  padding: 10px;
  background-color: #f3f3f3;
}

.hero__bg-item_third-line {
  bottom: 0;
  transform: translate(-35px, -20px);

  @media (max-width: 520px) {
    transform: translate(-35px, -15px);
  }
}

.hero__items {
  margin-top: 40px;
  padding-left: 114px;
  gap: 40px;

  @media (max-width: 1400px) {
    gap: 0;
  }

  @media (max-width: 1200px) {
    padding-left: 0;
  }

  @media (max-width: 590px) {
    gap: 20px;
  }

  @media (max-width: 520px) {
    margin-top: -70px;
  }
}

.hero__item {
  display: flex;
  gap: 10px;
  align-items: center;
  max-width: 250px;
}

.hero__item-img-block {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.hero__item-desc {
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
}

