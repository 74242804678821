.btn {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 240px;
  height: 60px;
  padding: 18px;
  line-height: 100%;
  text-align: center;
  background-image: linear-gradient(to right, #C90074 0%, #FC4EB2 100%);
  border: none;
  border-radius: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  box-shadow: 0 9px 10px rgba(201, 0, 116, 0.25);
  transition: all .3s;

  &::after {
    content: "";
    position: absolute;
    top: -2rem;
    bottom: -2rem;
    left: -20%;
    width: 2rem;
    transform: rotate(45deg);
    background-color: #fff;
    animation: blink 4s infinite;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    background-color: #FCE6C4;
  }

  &:hover {
    color: #8F5C10;
    animation: main-background .5s forwards;

    &::before {
      animation: background .5s forwards;
    }
  }

  &:focus {
    box-shadow: 0 9px 10px rgba(201, 0, 116, 0.25);
  }
}

@keyframes blink {
  0% {
    left: -30%;
  }

  30% {
    left: 130%;
  }

  100% {
    left: 130%;
  }
}

@keyframes background {
  0% {
    opacity: 0;
    background-color: #C90074;
  }
  30% {
    opacity: .9;
    background-color: #fed79d;
  }
  100% {
    opacity: 0;
    background-color: #fed79d;
  }
}

@keyframes main-background {
  0% {
    background-image: linear-gradient(to right, #C90074 0%, #FC4EB2 100%);
  }

  100% {
    background-image: linear-gradient(to right, #FFD18C 0%, #FCE6C4 100%);
  }
}