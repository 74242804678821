.results {
  position: relative;
  margin: 220px 0;
  padding: 50px 0 60px;
  min-height: 300px;
  background-color: #f2f2f2;

  @media (max-width: 1200px) {
    margin: 130px 0 120px;
  }
}

.results__wave {
  height: 112px;
  box-shadow: none;

  @media (max-width: 1600px) {
    height: 80px;
  }

  @media (max-width: 1400px) {
    height: 70px;
  }

  @media (max-width: 992px) {
    height: 50px;
  }

  @media (max-width: 520px) {
    height: 30px;
  }
}

.results__wave_bottom {
  top: calc(100% - 2px);
  bottom: initial;
  background-image: url("/img/results-bottom.png");
}

.results__wave_top {
  top: initial;
  bottom: calc(100% - 2px);
  background-image: url("/img/results-top.png");
}

.results__title {
  margin-bottom: 8px;
  text-align: center;
}

.results__desc {
  margin: 0 auto;
  max-width: 265px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 130%;
}

.results__btn {
  margin: 0 auto;
}

.results__name {
  color: #542259;
  font-size: 24px;
  font-weight: 700;
}

.results__weight {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  color: #b7b7b7;
}

.results__text {
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
}

.results__img-block {
  width: 100%;
  height: auto;
  max-width: 236px;
  max-height: 236px;
  margin: 0 auto;
}

.results__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/// SLIDER

.results__wrap {
  position: relative;
  width: 80%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;

  &:before, &:after {
    content: "";
    position: absolute;
    z-index: 3;
    background-image: url("/img/webp/result-ruler.webp");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 992px) {
      transform: scale(0.55);
    }
  }

  &:before {
    bottom: 100%;
    left: -250px;
    width: 500px;
    height: 250px;

    @media (max-width: 992px) {
      bottom: 110%;
    }
  }

  &:after {
    top: 90%;
    right: -330px;
    width: 600px;
    height: 300px;

    @media (max-width: 992px) {
      top: 100%;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;
    padding: 0;
  }
}
.owl-wrapper {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
}

.cards{
  position: relative;
  width: 100%;
  padding: 1em 0;
  align-items: center;
  margin: 10px 0 10px;
}

.card {
  position: relative;
  z-index: 4;
  display: flex;
  justify-content: center;
  min-height: 439px;
  margin: 0;
  padding: 32px 45px;
  border: none;
  border-radius: 50px;
  opacity: 1;
  transform: scale(0.87);
  transition: transform 0.4s 0.5s ease-out, opacity 1s ease;
  background-color: #fff;
  overflow: hidden;

  &__content{
    display: flex;
    align-items: center;
    text-align:center;
    color: #fff;
    opacity:1;

    .active &{
      opacity: 1;
      transition:opacity 0.4s ease;
    }
  }

  &__title{
    display:inline-block;
    font-size: 2em;
    overflow:hidden;

    span{
      display:inline-block;
      animation: slide-up 0.4s 0s ease both;

    .center.active &, .center.active.cloned:last-child &{
      opacity: 1;
      animation: slide-down 0.4s 0.4s ease both;
      transition: transform 0.3s 0.4s ease, opacity 0.3s ease;
    }
  }

  }
  .center &{
    opacity: 1;
    transform: scale(1);
  }

  @media (max-width: 1200px) {
    padding: 20px 30px;
    border-radius: 30px;
  }

  @media (max-width: 520px) {
    padding: 20px 20px;
    border-radius: 25px;
  }

}

.owl-theme .owl-dots, .owl-theme .owl-nav {
  display: block !important;
}

.owl-theme .owl-dots {
  margin-top: 0 !important;
}

.owl-theme .owl-dots .owl-dot span {
    width: 5px;
    height: 5px;
    display: inline-block;
    margin: 0 3px;
    border-radius: 50% !important;
    background: #D9D9D9;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 0;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  display: inline-block;
  width: 15px;
  height: 5px;
  margin: 0 3px;
  padding: 0;
  border-radius: 5px !important;
  background: #C90074;
}

.owl-carousel .owl-nav button.owl-next,.owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  height: 80px;
  width: 40px;
  top: 50%;
  background-image: url("/img/arrow.svg");
  background-size: 30%;
  background-position: center center;
  background-repeat: no-repeat;
  transition: all .1s;

  &:hover {
    opacity: 0.8;
    background-color: transparent;
    background-image: url("/img/arrow.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 30%;
  }

  span {
    display: none;
  }

  @media (max-width: 768px) {
    background-size: 30%;

    &:hover {
      opacity: 0.8;
    }
  }
}

.owl-carousel .owl-nav button.owl-next {
  transform: translate(50%, -70%) rotate(180deg);
  right: 31.5%;

  @media (max-width: 992px) {
    right: 9%;
  }
}

.owl-carousel .owl-nav button.owl-prev {
  transform: translate(-50%, -70%);
  left: 31.5%;

  @media (max-width: 992px) {
    left: 9%;
  }
}

@keyframes slide-down{
  0%{
    opacity: 0;
    transform: translate3d(0,-120%,0);
  }

  100%{
    opacity: 1;
    transform: translate3d(0,0%,0);
  }
}


@keyframes slide-up{
  0%{
    opacity: 1;
    transform: translate3d(0,0%,0);
  }

  100%{
    opacity: 0;
    transform: translate3d(0,-120%,0);
  }
}
