.form {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 580px;
  padding: 60px 85px;
  border-radius: 50px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 45px;
  }

  @media (max-width: 520px) {
    padding: 45px 25px;
  }

  @media (max-width: 359px) {
    padding: 42px 18px 32px 18px;
  }
}

.form__title {
  margin-bottom: 0;
  font-size: 36px;
  line-height: 100%;
  font-weight: 900;
  color: #441249;

  @media (max-width: 520px) {
    font-size: 30px;
  }
}

.form__subtitle {
  margin-bottom: 50px;
  font-size: 16px;
  font-weight: 500;

  @media (max-width: 520px) {
    margin-bottom: 30px;
  }
}

.form__btn {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form__input, .form__textarea {
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
  padding: 18px 30px;
  border: none;
  border-radius: 50px;
  background-color: #ebebeb;
  color: #828282;
  font-size: 16px;

  &::placeholder {
    color: #B1B1B1;
  }

  &:focus {
    outline: none;
  }
}

.form__textarea {
  height: 202px;
  margin-bottom: 0;
  border-radius: 40px;
  resize: none;

  @media (max-width: 520px) {
    height: 180px;
  }
}

._error {
  box-shadow: inset 0 0 7px rgba(red, .5);
}

