.why {
  position: relative;
  padding-top: 140px;
  padding-bottom: 80px;
  background-color: #fff;

  @media (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}

.why__title {
  margin-bottom: 117px;
  text-align: center;

  @media (max-width: 780px) {
    margin-bottom: 60px;
  }
}

.why__content {
  position: relative;
  margin-left: 112px;
  max-width: 580px;

  &:before, &:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
  }

  &:before {
    top: calc(50% - 10px);
    left: -115px;
    z-index: 3;
    height: 525px;
    width: 193px;
    background-image: url("/img/webp/why-left.webp");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 1200px) {
      width: 163px;
    }

    @media (max-width: 992px) {
      left: -130px;
      width: 163px;
    }

    @media (max-width: 767px) {
      left: -30px;
      top: -40px;
      transform: translateY(0);
      width: 140px;
    }

    @media (max-width: 480px) {
      left: -60px;
      top: -190px;
      transform: translateY(0) rotate(19deg);
      width: 100px;
    }
  }

  &:after {
    left: 70%;
    z-index: 1;
    height: 480px;
    border-radius: 50px;
    width: 150%;
    max-width: 780px;
    background-image: url("/img/webp/why.webp");
    background-size: cover;
    background-position: center;

    @media (max-width: 1600px) {
      max-width: 50vw;
    }

    @media (max-width: 767px) {
      display: block;
      position: relative;
      bottom: initial;
      top: initial;
      transform: translateY(0);
      left: initial;
      margin-top: 40px;
      max-width: 100%;
      height: 350px;
    }
  }

  @media (max-width: 1200px) {
    margin-left: 0;
  }
}

.why__inner {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 50px;
  padding: 45px 80px 45px 70px;
  background-color: #fff;
  box-shadow: -2px 4px 20px rgba(0, 0, 0, 0.1);

  @media (max-width: 1600px) {
    padding: 40px 50px;
  }

  @media (max-width: 767px) {
    margin-left: 80px;
  }

  @media (max-width: 480px) {
    margin-left: 0;
    border-radius: 30px;
    padding: 30px 30px;
  }

  @media (max-width: 480px) {
    margin-left: 0;
    padding: 30px 20px;
  }
}

.why__subtitle {
  margin-bottom: 15px;
  font-size: 24px;
  line-height: 100%;
  color: #542259;
  font-weight: 700;
}

.why__desc {
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
}