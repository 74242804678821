
//MEDIA
$desktop: 1200px;
$tablet: 768px;
$mobile: 576px;


//COLORS
$basic: #787878;

//FONTS
$mont: "Montserrat", Arial, sans-serif;
$musea: "Musea", Arial, sans-serif;


