.footer {
  position: relative;
  margin-top: 100px;
  padding-bottom: 65px;
  padding-top: 30px;
  background-color: #C90074;

  @media (max-width: 1600px) {
    margin-top: 80px;
  }

  @media (max-width: 1400px) {
    margin-top: 60px;
  }

  @media (max-width: 992px) {
    margin-top: 40px;
  }

  @media (max-width: 520px) {
    margin-top: 30px;
  }
}

.footer__wave {
  background-image: url("/img/wave-footer.png");
  bottom: 99%;
  top: initial;
  box-shadow: none;

  @media (max-width: 992px) {
    height: 40px;
  }

  @media (max-width: 520px) {
    height: 30px;
  }
}

.footer__logo-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;

  @media (max-width: 992px) {
    align-items: center;
  }
}

.footer__logo-desc {
  width: 100%;

  @media (max-width: 992px) {
    text-align: center;
  }
}

.footer__nav {
  @media (max-width: 1200px) {
    display: flex;
    justify-content: center;
  }
}

.footer__list {
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 992px) {
    flex-direction: row;
    justify-content: center;
  }

  @media (max-width: 520px) {
    flex-direction: column;
    align-items: center;
  }
}

.footer__item {
  flex-shrink: 0;
}

.footer__link {
  padding: 0 23px;
  font-weight: 500;
  font-size: 16px;
  color: #FFD0D0;
  font-weight: 300;
  transition: all .3s;

  &:hover {
    color: #FFD0D0;
    opacity: 0.6;
  }

  @media (max-width: 1200px) {
    padding: 0 10px;
  }

  @media (max-width: 992px) {
    padding: 0 8px;
  }
}

.footer__copy {
  text-align: right;
  font-family: $mont;
  font-weight: 600;
  font-size: 11.6px;
  color: #FFD0D0;

  @media (max-width: 992px) {
    align-items: center;
    margin-top: 0;
    text-align: center;
  }
}