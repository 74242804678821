.nav {
  display: flex;
  align-items: center;
}

.nav__item {
  flex-shrink: 0;

  &:first-child {
    & .nav__link:before {
      display: none;
    }
  }
}

.nav__link {
  display: block;
  position: relative;
  padding: 0 25px;
  font-weight: 500;
  font-size: 16px;

  &:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    top: 50%;
    left: 0;
    transform: translate(15px, -50%);
    border-radius: 50%;
    background-color: #C90074;
    opacity: 0;
    transition: all .3s;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  @media (max-width: 1600px) {
    padding: 0 10px;
    font-size: 15px;

    &:before {
      transform: translate(-2px, -50%);
    }
  }

  @media (max-width: 1200px) {
    padding: 10px;
    font-size: 20px;

    &:before {
      display: none;
    }
  }
}