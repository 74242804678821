.socials {
  display: flex;
  gap: 15px;
}

.socials__item {
  flex-shrink: 0;
}

.socials__link {
  display: block;
  max-width: 30px;
  transition: all .3s;

  &:hover {
    color: #FFD0D0;
    opacity: 0.6;
  }
}